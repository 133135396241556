import axios from 'axios';
import { redirect } from "react-router-dom";


const BASE_URL = process.env.REACT_APP_BASE_URL;

export default axios.create({
  baseURL: BASE_URL,
});


export async function postFormDataAny(url, role) {
  try {
    const data = await axios.post(process.env.REACT_APP_BASE_URL + url, role, { headers: { "Content-Type": "multipart/form-data" } });
    return data;
  } catch (error) {
    return error;
  }
}


export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
});

axiosPrivate.interceptors.request.use(
  (requestConfig) => {
    requestConfig.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('mainState')).token}`;

    return requestConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosPrivate.interceptors.response.use(
  (response) => response,
  (error) => {
    const expectedError =
      error.response && error.response.status >= 400 && error.response < 500;

    if (!expectedError) {
      if (error.response.status === 401) {
        localStorage.clear();
        // alert("Please Login!")
        window.location.reload();
        redirect("/login");
        return redirect("/login");
        // window.location('/')
      } else {
        alert('Oops! Something unexpected went wrong.');
      }
    }

    return Promise.reject(error);
  }
);
