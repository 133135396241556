function Platforms() {
    return (
        <>
            <option value="Referral">Referral</option>
            <option value="Extension">Extension</option>
            <option value="WalkIn">Walk In</option>
        </>
    );
}

export default Platforms;