import { useState, useEffect, useContext } from 'react';
import { post } from '../api/requests/main';
import { NavLink } from 'react-router-dom';
import { Context } from '../context/store';
import Skeleton from 'react-loading-skeleton'

import { toast } from 'react-toastify';

import { letrs } from '../helpers/getById'
import BookStatus from './sub-componments/Bookstatus';
import Channel from './components/platform';

import DatePicker from 'react-date-picker';
import { getTotalCount } from '../helpers/findRange';


function Dashboard() {
    const [mainState, setMainState] = useContext(Context);

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const [capacity, setCapacity] = useState(0);
    const [date, setDate] = useState(new Date())
    const [data, setData] = useState({})
    const [price, setPrice] = useState(null)
    const [table, setTable] = useState([])
    const [activeDiv, setActiveDiv] = useState('arrivals');

    const handleDivClick = (array) => {
        setTable(array);
    };
    const getData = async () => {
        try {
            const resMain = await post("main/dashboard", { property: mainState.selected_Property, date: date.toLocaleDateString('en-CA'), property: mainState?.selected_Property });
            const resPrice = await post("get-new-avilablilty", { dates: [date.toLocaleDateString('en-CA')], property: mainState.selected_Property });
            const resCapacity = await post("get-capacity", { property: mainState.selected_Property });
            resPrice.status === 200 ? setPrice(resPrice.data.days) : setPrice(null)
            resCapacity.status === 200 ? setCapacity(resCapacity?.data?.reduce((acc, obj) => acc + obj.max_capacity, 0)) : setCapacity(0)
            if (resCapacity.status === 202) { toast('Missing Settings') }
            if (resMain.status === 200) {
                setData(resMain.data)
                if (activeDiv == 'arrivals') { setTable(resMain.data.arrivals) }
                if (activeDiv == 'departures') { setTable(resMain.data.departures) }
                if (activeDiv == 'waitlist') { setTable(resMain.data.waitlist) }
                if (activeDiv == 'reserved') { setTable(resMain.data.reserved) }
                if (activeDiv == 'noShow') { setTable(resMain.data.noShow) }
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    useEffect(() => {
        getData();
    }, [date, mainState.selected_Property]);



    async function handleSave(bookings) {

        let mainElm = bookings.target.parentElement.parentElement
        let id = mainElm.id
        let BookStatus = mainElm.querySelector('[name="BookStatus"]')
        let dat = {
            "id": id,
            property: mainState.selected_Property,
            "bookingStatus": BookStatus.value
        }
        try {
            const response = await post('update-booking-info', dat);
        } catch (error) {

        } finally {
            getData();
        }
    }

    const renderPrice = () => {
        if (!price) {
            return <div>
                <Skeleton count={3} style={{ margin: '5px 0px 4px', height: '20px' }} />
            </div>;
        }
        return price.map((key, id) => {
            console.log(key)
            return (
                < b className='price' key={id} >
                    <span className='roomName'> {key.room_name} </span><span className='roomRate'>{key?.date[0]?.price}</span>
                </b >
            );
        });
    };



    return (
        <div className="dashboard">
            <header className="container">
                <DatePicker onChange={setDate} value={date} autoFocus />
            </header>
            <section className="container">

                <div className="today">
                    <span className="day">{getDayName(new Date(date))}</span>
                    <span className="num">{new Date(date).getDate()}</span>
                    <span className="mon">{monthNames[new Date(date).getMonth()]}</span>
                </div>
                <div className="Rate">
                    <h4>Rate</h4>
                    {renderPrice()}
                </div>
                <div className="occupancy">
                    <h4>Occupancy </h4>
                    <p>
                        <span>{data?.booking || 0}</span>
                        / {capacity}
                    </p>
                    <ul>
                        <li className='booked'>  <span>{data?.booking || 0}</span> <span>Booked</span> </li>
                        <li className='available'> <span>{capacity - data?.booking || 0}</span> <span>Available</span> </li>
                    </ul>
                </div>
                <div className="table">
                    <header>
                        <div className='left'>

                        </div>
                        <div className='right'>
                            <p> Reservation </p>
                            <h3> Activity</h3>
                        </div>
                    </header>
                    <section>
                        <div className={activeDiv === 'arrivals' ? 'active' : ''} onClick={() => { handleDivClick(data?.arrivals); setActiveDiv('arrivals'); }}>
                            {data?.arrivals ?
                                <h3>{data?.arrivals?.length}</h3> :
                                <Skeleton style={{ margin: '2px 0px 2px', height: '32px', width: "25px" }} />
                            }
                            <h5>Arrivals</h5>
                        </div>
                        <div className={activeDiv === 'departures' ? 'active' : ''} onClick={() => { handleDivClick(data?.departures); setActiveDiv('departures'); }}>
                            {data?.departures ?
                                <h3>{data?.departures?.length}</h3> :
                                <Skeleton style={{ margin: '2px 0px 2px', height: '32px', width: "25px" }} />
                            }
                            <h5>Departures</h5>
                        </div>
                        <div className={activeDiv === 'waitlist' ? 'active' : ''} onClick={() => { handleDivClick(data?.waitlist); setActiveDiv('waitlist'); }}>
                            {data?.waitlist ?
                                <h3>{data?.waitlist?.length}</h3> :
                                <Skeleton style={{ margin: '2px 0px 2px', height: '32px', width: "25px" }} />
                            }
                            <h5>Waiting List</h5>
                        </div>
                        <div className={activeDiv === 'reserved' ? 'active' : ''} onClick={() => { handleDivClick(data?.reserved); setActiveDiv('reserved'); }}>
                            {data?.reserved ?
                                <h3>{data?.reserved?.length}</h3> :
                                <Skeleton style={{ margin: '2px 0px 2px', height: '32px', width: "25px" }} />
                            }
                            <h5>Reserved</h5>
                        </div>
                        <div className={activeDiv === 'noShow' ? 'active' : ''} onClick={() => { handleDivClick(data?.noShow); setActiveDiv('noShow'); }}>
                            {data?.noShow ?
                                <h3>{data?.noShow?.length}</h3> :
                                <Skeleton style={{ margin: '2px 0px 2px', height: '32px', width: "25px" }} />
                            }
                            <h5>No Show</h5>
                        </div>
                        <div className={activeDiv === 'cancelled' ? 'active' : ''} onClick={() => { handleDivClick(data?.cancelled); setActiveDiv('cancelled'); }}>
                            {data?.cancelled ?
                                <h3>{data?.cancelled?.length}</h3> :
                                <Skeleton style={{ margin: '2px 0px 2px', height: '32px', width: "25px" }} />
                            }
                            <h5>Cancelled</h5>
                        </div>
                    </section>

                    <table className='table custom table-white vc'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Platform</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Bed</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {table?.map((a, id) => {
                                return <tr id={a.bid} key={id + activeDiv} >
                                    <td className='nowrap'>
                                        <b className='a' onClick={() => { setMainState({ type: "userId", payload: a.gid }); }}> {a.name} </b>
                                        <NavLink className="a table whatsapp" to={"/chat?phone=" + a.phone} >
                                            <i className="fa-brands fa-whatsapp"></i>
                                        </NavLink>
                                    </td>
                                    <td > <b className='a' onClick={() => { setMainState({ type: "bookingId", payload: a.bid }); }}>

                                        <Channel name={a.platform} />

                                    </b> </td>
                                    <td >{a.bookingDate} </td>
                                    <td>{Number(a.amount).toFixed(2)}</td>
                                    <td> {letrs[a.bed]} </td>
                                    <td className='flex-select'>
                                        <select className="sm form-select" name="BookStatus" defaultValue={a.status} >
                                            <BookStatus />
                                        </select>
                                        <button onClick={(s) => { handleSave(s) }} className='btn btn-primary'>Update </button>
                                    </td>
                                </tr>
                            })}
                        </tbody >
                    </table>
                </div>
            </section>
        </div>
    );
}

// Function to get day name
function getDayName(date) {
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return dayNames[date.getDay()];
}

export default Dashboard;
