import React, { useState, useRef, useEffect, useReducer, useContext } from 'react'
import getBed from '../../helpers/getBed'
import { Context } from '../../context/store';

import { Ebill } from '../invoice/ebill'
import { PDFDownloadLink, Document, Page, PDFViewer } from '@react-pdf/renderer';
import { post } from '../../api/requests/main';
import { Notification } from '../../components/common/logic/Toggles';
import { toast } from 'react-toastify';



function ViewUserInfo(p) {
    const [mainState, setMainState] = useContext(Context);
    const [data] = useState(p.data);
    const usrUpdate = useRef();


    const updateGuest = async () => {
        try {
            const response = await post('guests/guest-edit', usrUpdate.current);
            toast('Guest Saved!')
        } catch (error) { console.error(error); }
    }


    return (
        <>
            <h4> {data.user.name} {data.user.lastname} -
                {p.state.user.is_staff == '1' && data.passport.image !== "" ?
                    <PDFDownloadLink document={<Ebill dat={data} />} fileName={data.user.name + data.user.lastname + " - invoice.pdf"}>
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Invoice.'
                        }
                    </PDFDownloadLink>
                    : <></>}
            </h4>
            <div className='card dark'>
                <div className='grid-50'>

                    <form ref={usrUpdate} >
                        <div className='flex space'>
                            <input type='hidden' value={data.user.id} name='id' />
                            <div> First Name : <input className="form-control" name='name' type='text' defaultValue={data.user.name} /> </div>
                            <div> Last Name : <input className="form-control" name='lastname' type='text' defaultValue={data.user.lastname} /> </div>
                        </div>
                        <div className='flex space'>
                            {data.user.email && data.user.verified === "False" ?
                                <div> E-mail : <input className="form-control" disabled={data.user.verified === "True" ? true : false} name='email' type='email' defaultValue={data.user.email} /> </div>
                                : <a> </a>}
                            <div> Phone : <input className="form-control" name='phone' type='number' defaultValue={data.user.phone} /> </div>
                        </div>
                        <br />
                        <p className='btn btn-primary'
                            onClick={updateGuest}
                        > Update User </p>
                    </form>

                    {data.passport.image === "" ? <>NO PASSPORT</> :
                        <span className='rot-img'>
                            <img className='sm-pas' src={process.env.REACT_APP_BUCKET + "/passport/" + data.passport.image} />
                        </span>
                    }
                </div>
            </div>
            <div className='card dark'>
                <h4>Payments </h4>
                <table>
                    <tr>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Name</th>
                        <th>Payment Method</th>
                        <th>Date</th>
                    </tr>
                    {data.payments.map((a) => {
                        return <>
                            <tr>
                                <th>{a.type}</th>
                                <th>{Number(a.amount).toFixed(1)}</th>
                                <th>{a.description}</th>
                                <th>{a.name}</th>
                                <th>{a.paymentMethod}</th>
                                <th>{new Date(a.date).toLocaleDateString()}</th>
                            </tr>
                        </>
                    })}
                </table>
            </div>
            <div className='card dark'>
                <h4>Deposit </h4>
                <table>
                    <tr>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>date</th>
                        <th>Description</th>
                    </tr>
                    {data.deposit.map((a) => {
                        return <>
                            <tr>
                                <th>{a.type}</th>
                                <th>{Number(a.amount).toFixed(1)}</th>
                                <th>{a.date}</th>
                                <th>{a.description}</th>
                            </tr>
                        </>
                    })}
                </table>
            </div>
            <div className='card dark'>
                <h4>Booking </h4>
                <table>
                    <tr>
                        <th>Amount</th>
                        <th>Bed Name</th>
                        <th>BookingDate</th>
                        <th>Platform</th>
                    </tr>
                    {data.bookingDate.map((a) => {
                        return <>
                            <tr>
                                <th>{a.amount}</th>
                                <th>{getBed(a.bed)}</th>
                                <th>{a.bookingDate}</th>
                                <th>{a.platform}</th>
                            </tr>
                        </>
                    })}
                </table>
            </div>
        </>
    )
}

export default ViewUserInfo