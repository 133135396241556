import React, { useState, useEffect } from 'react';
import { post } from '../../api/requests/main';

export function ListProperties({ mainState, setMainState }) {

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (!mainState.properties) {
            post('list-properties')
                .then(response => {
                    setOptions(response.data);
                    if (!mainState?.selected_Property) {
                        setMainState({ type: "selected_Property", payload: response.data[0].id });

                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } else {
            setOptions(mainState.properties)
        }
    }, []);

    return (
        <>
            {options.map((option) => (
                <option key={option.id} value={option.id} selected={mainState.selected_Property == option.id ? true : false} >{option.name}</option>
            ))}
        </>
    );
}