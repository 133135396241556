import { useContext, useEffect, useState } from 'react';
import { Context } from '../context/store';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';


function Login() {
    const navigate = useNavigate();

    const [disable, setDisable] = useState(false);
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const LOGIN_URL = process.env.REACT_APP_BASE_URL + 'token';

    const [mainState, setMainState] = useContext(Context);

    useEffect(() => {
        if (mainState.token) {
            navigate('/table', { replace: true });
        }
    }, [mainState.token, navigate]);

    function transformConfigArray(configArray) {
        const result = {};

        configArray.forEach(item => {
            result[item.name] = item.value;
        });

        return result;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisable(true);
        try {
            const response = await axios.post(
                LOGIN_URL, { email: user, password: pass },
                { headers: { 'Content-Type': 'application/json' }, }
            );
            let r = response?.data;

            if (r.view_only) {
                window.location.href = 'https://finance.hostelmate.co';
                return;
            }

            if (Number(r.is_staff) === 7) {
                return;
            }

            let usr = {
                "id": r.id,
                "is_superuser": r.is_superuser,
                "username": r.username,
                "first_name": r.first_name,
                "last_name": r.last_name,
                "email": r.email,
                "view_only": r.view_only,
                "is_staff": r.is_staff,
                "properties": r.properties,
                "selected_Property": r.properties[0].id,
            };

            setMainState({ type: 'token', payload: r.access });
            setMainState({ type: 'user', payload: usr });
            setMainState({ type: 'configurations', payload: transformConfigArray(r.configurations) });
            setMainState({ type: 'properties', payload: r.properties });
            setMainState({ type: 'selected_Property', payload: r.properties[0].id });

            toast('Login Success');
        } catch (err) {
            setDisable(false);
            toast('error');
        }
    };


    return (
        <div className='login--container'>
            <form onSubmit={handleSubmit}>
                <div className='card small mt5'>
                    <h4>Hostel Mate Login </h4>
                    <br />
                    <label> name <input className='form-control' type='email' required onChange={(a) => { setUser(a.target.value); }} value={user} /> </label>
                    <label> password: <input className='form-control' type='password' onChange={(a) => { setPass(a.target.value); }} value={pass} />
                    </label>
                    <button disabled={disable}>Send</button>
                    <p
                        style={{ paddingTop: '15px', fontSize: '14px', textAlign: 'center', color: '#888888' }}
                    > Forgot Password? <a href='/password-reset' style={{ color: '#0e87ff' }}>Reset</a> </p>
                </div>
            </form>
        </div>
    );
}

export default Login;
