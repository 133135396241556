import React, { useEffect, useState, useContext } from 'react'
import { country } from '../../helpers/getCountry';
import { post } from '../../api/requests/main';
import User from '../components/user';
import * as total from '../../helpers/getTotal';
import { Context } from '../../context/store';


function Payment() {
    const [mainState, setMainState] = useContext(Context);
    const [load, setLoad] = useState(false);
    const [user, setUser] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        // if (!data.length) {
        getcalender()
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getcalender = async (e) => {
        setLoad(true)
        try {
            const response = await post('list-all-users',{property: mainState.selected_Property,});
            if (response.status === 200) {
                setLoad(false)
                setData(response.data);
            }
        } catch (error) { setLoad(false) }
    }
    const viewUser = (s) => {
        setUser(s)
    }

    const closePopupUser = (s) => {
        setUser(false)
    }

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <table className='table custom table-white vc'>
                        <tr>
                            <th>Name</th>
                            <th>Booking Amount</th>
                            <th>Payment Amount</th>
                            <th>Total</th>

                        </tr>
                        {data.map(a => {
                            return <tr key={a.id + 'paymentT'} >
                                <td>
                                    <p className='a' onClick={() => { setMainState({ type: "userId", payload: a.guest_id }); }} >{a.name} {a.lastname}</p>
                                </td>
                                <td>{Number(a.total_booking_amount)}</td>
                                <td>{Number(a.total_payment_amount)}</td>
                                <td><b>{Number(a.total_payment_amount - a.total_booking_amount).toFixed(2)}</b></td>
                            </tr>
                        })}
                    </table>
                </div>
            </div>
            {load ? <div className='load' > <span className='tar'></span></div> : <></>}

            {user ? (
                <div className='popup'>
                    {/* <div dangerouslySetInnerHTML={{__html:`<style>html{overflow:hidden}</style>`}}></div> */}

                    <div className='child wide'>
                        <span onClick={closePopupUser}>x</span>
                        <User user={user} auth={mainState.user} setState={setMainState} />
                    </div>
                </div>
            ) : (<></>)}

        </>
    )
}

export default Payment