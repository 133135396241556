import React, { useEffect, useState, useContext } from "react";
import { getPrivate, post } from "../../api/requests/main";
import { formatDate } from "../../helpers/dateFormat";
import { Context } from '../../context/store';

import SingleChat from "./SingleCHat";


import { useLocation } from 'react-router-dom';


const Chat = () => {
  const [mainState, setMainState] = useContext(Context);
  const [chatList, setChatList] = useState([])
  const [userData, setUserData] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [userName, setUserName] = useState(false)
  const [isWhatsappDisabled, setIsWhatsappDisabled] = useState(false)
  const [noData, setNoData] = useState(false)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const phone = searchParams.get('phone');
  const [phoneId, setPhoneId] = useState(phone || 0)

  function removeDuplicatePhones(data) {
    const uniquePhones = data.reduce((unique, item) => {
      if (!unique[item.phone]) {
        unique[item.phone] = item;
      }
      return unique;
    }, {});
    let array = Object.values(uniquePhones);
    return array.sort((a, b) => new Date(b.time) - new Date(a.time));
  }

  const getAllChats = async () => {

    try {
      const response = await post('whatsapp/list-chat', { property: mainState.selected_Property });
      if (response.status === 202) {
        setIsWhatsappDisabled(true)
        return response
      }
      if (response.status === 200) {
        if (response.data.length === 0) {
          setNoData(true)
        }
        setChatList(removeDuplicatePhones(response.data));
        return response
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (mainState?.selected_Property) {
      getAllChats();
    }
  }, [mainState])


  useEffect(() => {
    if (phoneId) {
      handleSingleChatDetails()
    }
  }, [phoneId, setPhoneId])

  const handleSingleChatDetails = async () => {
    try {
      const response = await post('whatsapp/view-chat', { property: mainState.selected_Property, phone: phoneId });
      if (response.status === 200) {
        setUserName(response.data.user);
        setUserData(response.data.chat);
      }
    } catch (error) {
      setUserData(false)
    }

  }

  if (isLoading) {
    return <>
      <div className="chat-body">
        <div className="chat-window none">
          Loading ...
        </div>
      </div>
    </>
  }

  if (isWhatsappDisabled) {
    return <>
      <div className="chat-body">
        <div className="chat-window none">
          WhatsaApp is not enabled
        </div>
      </div>
    </>
  }
  if (noData) {
    return <>
      <div className="chat-body">
        <div className="chat-window none">
          No messages found
        </div>
      </div>
    </>
  }

  return (
    <div className="chat-body">
      <div className="chat-window">

        <div className="conversations">
          {chatList.map((user) => {
            return (
              <div className={user.phone === phoneId ? "chat-details active" : "chat-details"} onClick={() => { setPhoneId(user.phone) }}>
                <span>{user.fullname}</span>
                <span className="time">{formatDate(user.time)}</span>
              </div>
            );
          })}
        </div>
        {userData && (
          <SingleChat phoneId={phoneId} userData={userData} setUserData={setUserData} userName={userName} />
        )}
      </div>
    </div>
  );
};

export default Chat;
